import React from "react";
import PropTypes from "prop-types";

/**
 * Tooltip
 *
 * @component
 */

const Tooltip = ({ text, top, left, style }) => {
  return (
   <div
    style={{
        position:'fixed',
        top: top,
        left: left,
        ...style
    }}
   >
    {text}
   </div>
  );
};

Tooltip.propTypes = {
    text: PropTypes.string.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
};
export default Tooltip;
