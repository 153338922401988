import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const tooltipReducer = (state = initialState.tooltip, action) => {
    switch (action.type) {      
      case types.SHOW_TOOLTIP:
        return {
          ...state,
          show: true,
          location: action.location,
          hoveredElementBoundingRectangle: action.hoveredElementBoundingRectangle
        };
      case types.HIDE_TOOLTIP:
        return {
          ...state,
          show: false,
          location: {x:null, y:null}
        };
      default:
        return state;
    }
  };
  
  export default tooltipReducer;