import React from "react";
import PropTypes from "prop-types";
import AgentSocial from "./AgentSocial";
import AgentLinks from "./AgentLinks";
import AgentLinksOther from "./AgentLinksOther";

/**
 * links section of an agent edit
 *
 * @component
 */

const AgentLinksContainer = ({ agentSocial, agentSocialErrors, handleSocialChanges, agentReview, handleReviewChanges, agentOther, agentOtherErrors, handleOtherAdd, handleOtherDelete, isBrivityUser }) => {
  return (
    <>
      <AgentSocial social={agentSocial} errors={agentSocialErrors} handleSocialChanges={handleSocialChanges} isBrivityUser={isBrivityUser} />
      <AgentLinks links={agentReview} handleLinksChanges={handleReviewChanges} title="Review" icon="/images/icons/share-2.svg" errors={{}} />
      <AgentLinksOther links={agentOther} handleOnAdd={handleOtherAdd} handleOnDelete={handleOtherDelete} title="Other" icon="/images/icons/share-2.svg" errors={agentOtherErrors} />
    </>
  );
};

AgentLinksContainer.propTypes = {
  /**
   * agent social links object
   */
  agentSocial: PropTypes.object.isRequired,
  /**
   * possible errors on input data for social
   */
  agentSocialErrors: PropTypes.object.isRequired,

  /**
   * function that handles social link changes
   */

  handleSocialChanges: PropTypes.func.isRequired,
  /**
   * function that handles review link changes
   */

  handleReviewChanges: PropTypes.func.isRequired,
  /**
   * function that handles other delete
   */

  handleOtherDelete: PropTypes.func.isRequired,
  /**
   * function that handles other add
   */

  handleOtherAdd: PropTypes.func.isRequired,
  /**
   * other links array
   */
  agentOther: PropTypes.array.isRequired,
};

export default AgentLinksContainer;
