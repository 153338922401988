import React, { useState } from "react";
import Dropdown from "../controls/Dropdown";
import { phoneTypes } from "../common/DropdownTypes";
import { IMaskInput } from "react-imask";
import PropTypes from "prop-types";
import {showToolTip, hideToolTip} from "../../store/actions/tooltipActions"
import { useDispatch } from "react-redux";
/**
 * Phone form container with phone, type and delete
 *
 * @component
 */

const PhoneForm = ({ phone, errors, handleOnChange, isEdit, handleOnDelete, isBrivityUser }) => {
  const [number, setNumber] = useState(phone.number);

  const dispatch = useDispatch();

  const handleTypeChange = (newType) => {
    handleOnChange({ ...phone, type: newType });
  };

  const handlePhoneChange = (value) => {
    setNumber(value);
    handleOnChange({ ...phone, number: value });
  };

  return (
    <div id="phone-details-grid" className="info-group" key={phone.id}>
      <div className="header">
        <div className="header-label">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone">
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
          </svg>
          <span>Phone</span>
        </div>
        {isEdit && (
          <div onClick={() => handleOnDelete(phone.id)} className="header-actions">
            <svg data-tippy="Remove" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2 action-svg-delete">
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </div>
        )}
      </div>
      <div className="data-collection">
        <div className="input-group">
          <input type="hidden" name="phoneId" value={phone.id} />
          <div className="input-wrapper flex2">
            <label htmlFor="phone">Phone*</label>
            <IMaskInput 
              mask={"(000)000-0000"} 
              unmask={true} 
              onAccept={(value) => handlePhoneChange(value)} 
              type="text" 
              name="phone" 
              placeholder="(123)123-1234" 
              value={number} 
              className="hoverable"
              onMouseEnter={(e) => {
                dispatch(showToolTip({x: e.clientX, y: e.clientY}, e.target.getBoundingClientRect()));
              }}
              onMouseLeave={() => {
                dispatch(hideToolTip())
              }} 
            />
            {errors.number && <div className="error">{errors.number}</div>}
          </div>
          <div className="input-wrapper">
            <label htmlFor="phone-type">Type*</label>
            <Dropdown 
              list={phoneTypes} 
              name={`phoneType${phone.id}`} 
              handleOnChange={handleTypeChange} 
              selected={phone.type}  
              title="Type"
              onMouseEnter={(e) => {
                dispatch(showToolTip({x: e.clientX, y: e.clientY}, e.target.getBoundingClientRect()));
              }}
              onMouseLeave={() => {
                dispatch(hideToolTip())
              }} 
             />

            {errors.type && <div className="error">{errors.type}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

PhoneForm.propTypes = {
  /**
   * object for a phone
   * {default: "1", id: "1", number: "9877897987", type= "celullar"}
   */
  phone: PropTypes.object.isRequired,
  /**
   * object of errors
   * {number: "Phone is required", type: "Type is required"}
   */
  errors: PropTypes.object.isRequired,
  /**
   * used to display or hide the delete button
   */
  isEdit: PropTypes.bool.isRequired,
  /**
   * function to handle on change event for the phone object
   */
  handleOnChange: PropTypes.func.isRequired,
  /**
   * function to handle the delete of phone
   */
  handleOnDelete: PropTypes.func,
};

export default PhoneForm;
