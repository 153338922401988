export default {
  address: {
    countries: [],
    countriesFetching: false,
    countriesFetched: false,
    countriesFetchError: false,
    states: {},
    statesFetching: false,
    statesFetched: false,
    statesFetchError: false,
    cities: {},
    citiesFetching: false,
    citiesFetched: false,
    citiesFetchError: false,
  },
  icon: {
    icons: [],
    iconsFetching: false,
    iconsFetched: false,
    iconsFetchError: false,
  },
  auth: {
    authError: null,
    authIsLoggedIn: false,
    authFetching: false,
    authUser: {
      email: "",
      firstName: "",
      lastName: "",
      brUserId: "",
      sitebuilder_user_id: "",
      token: "",
      site_url: "",
      site_id: "",
      isBrivityUser: false
    },
  },
  roster: {
    agentsFetching: false,
    agentsLoaded: false,
    agentsLoadError: false,
    agents: [],
    officesFetching: false,
    officesLoaded: false,
    officesLoadError: false,
    offices: [],
    agentDeleteError: false,
    agentDeleting: false,
    agentUpdatingShowOnRoster: false,
    agentUpdatingShowOnRosterError: false,
    agentUpdatingFeatured: false,
    agentUpdatingFeaturedError: false,
    agentUpdatingEnableProfilePage: false,
    agentUpdatingEnableProfilePageError: false,
    settings: { sort_column: "created", sort_order: "desc" },
    rosterSettingsFetching: false,
    rosterSettingsLoaded: false,
    rosterSettingsLoadError: false,
    rosterSettingsSaving: false,
    rosterSettingsSaved: false,
    rosterSettingsSaveError: false,
   
  },
  tooltip: {
    show:false,
    location: {x:null, y:null},
    hoveredElementBoundingRectangle: {}
  }
};
