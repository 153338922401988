import * as types from "./actionTypes";

export const showToolTip = (location, hoveredElementBoundingRectangle) => {
    return (dispatch) => {
        dispatch({ type: types.SHOW_TOOLTIP, location, hoveredElementBoundingRectangle });
    }
}

export const hideToolTip = () => {    
    return (dispatch) => {
        dispatch({ type: types.HIDE_TOOLTIP });
    }
}