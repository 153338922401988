import authReducer from "./authReducer";
import rosterReducer from "./rosterReducer";
import addressReducer from "./addressReducer";
import { combineReducers } from "redux";
import iconReducer from "./iconReducer";
import tooltipReducer from "./tooltipReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  roster: rosterReducer,
  address: addressReducer,
  icon: iconReducer,
  tooltip: tooltipReducer
});

export default rootReducer;
