import React, { useState } from "react";
import Dropdown from "../controls/Dropdown";
import { addressTypes } from "../common/DropdownTypes";
import PropTypes from "prop-types";
import {showToolTip, hideToolTip} from "../../store/actions/tooltipActions"
import { useDispatch } from "react-redux";

/**
 * Address form container with address, type and delete
 *
 * @component
 */

const AddressForm = ({ address, errors, handleOnChange, isEdit, handleOnDelete, countryList, isCountryLoading, stateList, isStateLoading, isBrivityUser }) => {
  const [streetAddress, setStreetAddress] = useState(address.streetAddress);
  const [zip, setZip] = useState(address.zip);
  const [state, setState] = useState(address.state_name);
  const [city, setCity] = useState(address.city);

  const dispatch = useDispatch();

  const handleTypeChange = (newType) => {
    handleOnChange({ ...address, type: newType });
  };

  const handleStreetAddressChange = (e) => {
    setStreetAddress(e.target.value);
    handleOnChange({ ...address, streetAddress: e.target.value });
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
    handleOnChange({ ...address, city: e.target.value });
  };

  const handleZipChange = (e) => {
    setZip(e.target.value);
    handleOnChange({ ...address, zip: e.target.value });
  };

  const handleStateChange = (val, name) => {
    handleOnChange({ ...address, state: val, state_name: name });
    setState(name);
  };

  const handleCountryChange = (val, name) => {
    setState("");
    handleOnChange({ ...address, country: val, country_name: name, state: "", state_name: "" });
  };

  return (
    <div id="address-details-grid" className="info-group">
      <div className="header">
        <div className="header-label">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-compass">
            <circle cx="12" cy="12" r="10"></circle>
            <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76"></polygon>
          </svg>
          <span>Address</span>
        </div>
        {isEdit && (
          <div onClick={() => handleOnDelete(address.id)} className="header-actions">
            <svg data-tippy="Remove" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2 action-svg-delete">
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>
          </div>
        )}
      </div>
      <div className="data-collection">
        <div className="input-group">
          <div className="input-wrapper flex2">
            <label htmlFor="street">Street Address*</label>
            <input 
              type="text" 
              onChange={handleStreetAddressChange} 
              name="streetAddress" 
              placeholder="123 S. State St." 
              value={streetAddress} 
              onMouseEnter={(e) => {
                dispatch(showToolTip({x: e.clientX, y: e.clientY}, e.target.getBoundingClientRect()));
              }}
              onMouseLeave={() => {
                dispatch(hideToolTip())
              }} 
            />
            {errors.streetAddress && <div className="error">{errors.streetAddress}</div>}
          </div>
        </div>
        <div className="input-group m-t-15">
          <div className="input-wrapper flex2">
            <label htmlFor="city">City*</label>
            <input
              type="text" 
              onChange={handleCityChange} 
              name={`city${address.id}`} 
              placeholder="City" 
              value={city} 
              onMouseEnter={(e) => {
                dispatch(showToolTip({x: e.clientX, y: e.clientY}, e.target.getBoundingClientRect()));
              }}
              onMouseLeave={() => {
                dispatch(hideToolTip())
              }} 
            />
            {errors.city && <div className="error">{errors.city}</div>}
          </div>
          <div className="input-wrapper">
            <label htmlFor="state">State*</label>
            <Dropdown 
              list={stateList[address.country]} 
              name={`state${address.id}`} 
              handleOnChange={handleStateChange} 
              isLoading={isStateLoading} 
              isLoaded={!isStateLoading} 
              selected={state} 
              title="State"  
              onMouseEnter={(e) => {
                dispatch(showToolTip({x: e.clientX, y: e.clientY}, e.target.getBoundingClientRect()));
              }}
              onMouseLeave={() => {
                dispatch(hideToolTip())
              }} 
            />
            {errors.state && <div className="error">{errors.state}</div>}
          </div>
          <div className="input-wrapper">
            <label htmlFor="zip">Zip*</label>
            <input 
              type="text" 
              name="zip" 
              onChange={handleZipChange} 
              placeholder="12345" 
              value={zip} 
              onMouseEnter={(e) => {
                dispatch(showToolTip({x: e.clientX, y: e.clientY}, e.target.getBoundingClientRect()));
              }}
              onMouseLeave={() => {
                dispatch(hideToolTip())
              }} 
            />
            {errors.zip && <div className="error">{errors.zip}</div>}
          </div>
        </div>
        <div className="input-group m-t-15">
          <div className="input-wrapper ">
            <label htmlFor="country">Country*</label>
            <Dropdown
              list={countryList} 
              name={`country${address.id}`} 
              handleOnChange={handleCountryChange} 
              isLoading={isCountryLoading} 
              isLoaded={!isCountryLoading} 
              selected={address.country_name} 
              title="Country" 
              onMouseEnter={(e) => {
                dispatch(showToolTip({x: e.clientX, y: e.clientY}, e.target.getBoundingClientRect()));
              }}
              onMouseLeave={() => {
                dispatch(hideToolTip())
              }} 
            />

            {errors.country && <div className="error">{errors.country}</div>}
          </div>
          <div className="input-wrapper">
            <label htmlFor="addressType">Type*</label>
            <Dropdown 
              list={addressTypes} 
              name={`addressType${address.id}`} 
              handleOnChange={handleTypeChange} 
              selected={address.type} 
              title="Type" 
              onMouseEnter={(e) => {
                dispatch(showToolTip({x: e.clientX, y: e.clientY}, e.target.getBoundingClientRect()));
              }}
              onMouseLeave={() => {
                dispatch(hideToolTip())
              }} 
            />
            {errors.type && <div className="error">{errors.type}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

AddressForm.propTypes = {
  /**
   * object for an address
   * "address": {
   * "id": "1",
   * "streetAddress": "12215 S 900 E Ste A",
   * "city": "Draper",
   * "zip": "84020",
   * "state": "1",
   * "state_name": "Utah",
   * "country": "1",
   * "country_name": "United States",
   * "type": "work",
   * "default": "0"
   * }
   */
  address: PropTypes.object.isRequired,
  /**
   * object of errors
   * {email: "Email is required", type: "Type is required"}
   */
  errors: PropTypes.object.isRequired,
  /**
   * used to display or hide the delete button
   */
  isEdit: PropTypes.bool.isRequired,
  /**
   * function to handle tab change
   */
  handleOnChange: PropTypes.func.isRequired,
  /**
   * function to handle the delete of email
   */
  handleOnDelete: PropTypes.func,
  /**
   * list of countries for countries dropdown
   * [
   * {checked: true, name: "United States", value: "1"}",
   * {checked: false, name: "Canada", value: "3"}",
   * {checked: false, name: "Mexico", value: "2"}"
   * ]
   */
  countryList: PropTypes.array.isRequired,
  /**
   * is countries are loading in case api is being called
   */
  isCountryLoading: PropTypes.bool.isRequired,
  /**
   * list of states for states dropdown
   * {
   * 1: "[{checked: true, name: "Abraham", value: "66804"},...]",
   * 2: "[{checked: true, name: "Draper", value: "66804"},...]",
   * 3: "[{checked: true, name: "Jalisco", value: "66804"},...]"
   * }
   */
  stateList: PropTypes.object.isRequired,
  /**
   * is states are loading in case api is being called
   */
  isStateLoading: PropTypes.bool.isRequired,
};

export default AddressForm;
