import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import RosterList from "./components/roster/RosterList";
import AgentEdit from "./components/agent/AgentEdit";
import Logmein from "./components/auth/Logmein";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from "./components/controls/PrivateRoute";
import PreferredLender from "./components/preferredLender/PreferredLender";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import TouchBackend from "react-dnd-touch-backend";

function App() {
    // simple way to check whether the device support touch (it doesn't check all fallback, it supports only modern browsers)
    const isTouchDevice = () => {
      if ("ontouchstart" in window) {
        return true;
      }
      return false;
    };
    
  const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;
  return (
    <BrowserRouter>
      <div className="App">
      <DndProvider backend={backendForDND}>
        <Navbar />
        <Switch>
          <Route path="/logmein/:token" component={Logmein} />
          <PrivateRoute path="/roster-list" component={RosterList} />
          <PrivateRoute path="/preferred-lender" component={PreferredLender} />
          <PrivateRoute path="/agent/:id" component={AgentEdit} />
          <PrivateRoute path="/" component={RosterList} />
        </Switch>
        <ToastContainer hideProgressBar />
       </DndProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
