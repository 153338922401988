import React from "react";
import PropTypes from "prop-types";
/**
 * Dropdown component that internally uses radio buttons to display
 *
 * @component
 * @example
 * const list = [{checked: true,name:"Option 1",value:"1"},{checked: false,name:"Option 2",value:"2"}]
 * const name = "dropdown-test"
 * const title = "Choose an option"
 * const selected = "1"
 * return (
 *   <Dropdown list={list} name={name} title={title} selected={selected} isLoading={false} isLoaded={false} handleOnChange={() => {}} />
 * )
 */
const Dropdown = ({ list, name, title, selected, isLoading, isLoaded, handleOnChange, disabled=false, onMouseEnter = () => {}, onMouseLeave = () => {} }) => {
  //const [refreshList, setRefreshList] = useState( true );

  let selectedTitle = selected !== "" && typeof selected !== "undefined" ? selected.charAt(0).toUpperCase() + selected.slice(1) : title;

  const generateList = (itemsList, chosenItem) => {
    if (typeof itemsList != "undefined") {
      return itemsList.map((item) => {
        let valueToCheck = item.value;
        let nameToCheck = item.name;
        if (isNaN(chosenItem)) {
          valueToCheck = item.value.toLowerCase();
          nameToCheck = item.name.toLowerCase();
        }

        if (valueToCheck === chosenItem.toLowerCase() || nameToCheck === chosenItem.toLowerCase()) {
          selectedTitle = item.name;
          item.checked = true;
        } else {
          item.checked = false;
        }
        return item;
      });
    }
    return itemsList;
  };

  let newList = typeof list !== "undefined" ? generateList(list, selected) : [];

  const handleOptionClick = (e) => {
    const newType = e.target.childNodes[0].childNodes[1].value;
    const newTypeTitle = e.target.childNodes[0].childNodes[1].getAttribute("data-title");
    newList = generateList(newList, newType);
    handleOnChange(newType, newTypeTitle);
  };

  if (isLoading) {
    /* if(refreshList){
            setRefreshList(false);
        } */
    return (
      <div className="dropdown m-r-15">
        <button type="button" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span className="toggle-label">Loading...</span>
        </button>
      </div>
    );
  }

  /* if (isLoaded && typeof list != "undefined") {
    //setRefreshList(true);
    for (let index = 0; index < newList.length; index++) {
      const element = list[index];
      if (element.checked === true) {
        //setselectedTitle( element.name );
        selectedTitle = element.name;
        break;
      }
    }
  } */

  if (isLoaded || typeof isLoaded === "undefined") {
    return (
      <div className="dropdown m-r-15">
        <button 
          type="button" 
          className="dropdown-toggle" 
          data-toggle="dropdown" 
          aria-haspopup="true" 
          aria-expanded="false" 
          disabled={disabled} 
          onMouseEnter={(e) => onMouseEnter(e) } 
          onMouseLeave={(e) => onMouseLeave(e)} 
        >
          <span className="toggle-label">{selectedTitle}</span>
          <svg className="caret-down" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
        <ul className="dropdown-menu post-filters">
          {newList &&
            newList.map((option, index) => {
              return (
                <li className="item dropdown-item" key={index} onClick={handleOptionClick}>
                  <label className="new-radio">
                    {option.name}
                    <input type="radio" name={name} value={option.value} data-title={option.name} onChange={(e) => console.log(e)} checked={option.checked ? true : false} />
                    <span className="radio-indicator"></span>
                  </label>
                </li>
              );
            })}
        </ul>
      </div>
    );
  }

  return null;
};

Dropdown.defaultProps = {
  isLoading: false,
  isLoaded: true,
};

Dropdown.propTypes = {
  /**
   * array of elements to display
   */
  list: PropTypes.array.isRequired,
  /**
   * name attribute to assign to the dropdown
   */
  name: PropTypes.string.isRequired,
  /**
   * default title to display if not option is selected
   */
  title: PropTypes.string.isRequired,
  /**
   * displays loading... if list to pass isn't loaded
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * indicates that data finished loading and loads it on the component
   */
  isLoaded: PropTypes.bool.isRequired,
  /**
   * external func that handles the on change, internaly the state changes to handle the new selection
   */
  handleOnChange: PropTypes.func.isRequired,
};

export default Dropdown;
